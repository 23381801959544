/* Styles for Desktop Mode */
.solid {
    border-top: 3px solid #bbb;
    padding: 1em;
    margin: 0.5em 2em .5em 2em;
  }

  .solid-bottom {
    border-top: 6px solid #bbb;
    margin-top: .5em;
    margin: 0.5em;
  }
  
  .noMerchBox {
    display: flex;
    justify-content: center;
  }
  
  .noMerch {
    color: #bbb;
    text-align: center;
    font-size: 6vmin;
    padding: 4.5vmax 15vmax 4.5vmax 15vmax;
    border: #bbb solid 0.3em;
    max-width: 15%;
  }
  
  .storeContainer {
    background-color: black;
    color: white;
    min-height: 55vmax;
  }
  
  .flip-card {
    box-shadow: 0 0 7px #ccc;
    background-color: white;
    color: black;
  }
  
  .merchItemsContainer {
    display: flex;
    justify-content: space-evenly;
  }
  
  .merchItemBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .merchItemPrice {
    margin-top: 1rem;
    font-size: 1.8rem;
  }

  .merchItemShipping {
    margin-top: .2rem;
    font-size: 1rem;
  }
  
  .merchButton {
    margin-bottom: 1rem;
    padding: .8rem 5rem;
  }
  
  .flip-card {
    transition: opacity 5.5s ease-in-out;
  }
  
  .shirtSize {
    text-align: center;
    font-size: 1.5rem;
  }

  .request-form-container {
    background-color: black;
  }
  
  .request-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: rgb(78, 78, 78);
    box-shadow: 0 0 7px #ccc;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .success-message {
    margin-top: 0.8rem;
    font-size: 1.4rem;
  }

  .flip-card-inner:hover {
    box-shadow: 0 0 1.2rem #ccc;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input {
    width: 94%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  select {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background-color: #1bb919;
    color: #fff;
    padding: 12px 45px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0.6rem;
    box-shadow: 0 0 3px #353535;
  }
  
  button:hover {
    opacity: 0.7 !important;
  }
  
  /* Styles for Mobile Mode */
  @media screen and (max-width: 768px) {
      
      .flip-card {
        box-shadow: 0 0 7px #ccc;
        width: 50%;
      }

      .flip-card-inner {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .merchItemPrice {
        margin-top: .4rem;
        font-size: 1.2rem;
      }
    
      .merchItemShipping {
        margin-top: .2rem;
        font-size: .7rem;
      }

      .shirtSize {
        text-align: center;
        font-size: 1rem;
      }

      img {
        width: 100%;
      }

      .merchButton {
        margin-bottom: 1rem;
        padding: .4rem 3rem;
      }
  }
  