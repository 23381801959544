.tourContainer {
    background-color: black;
    color: white;
    min-height: 45vmax;;
}

.tourDateHeader {
    text-align: center;
    font-size: 7vmin;
    padding-top: 1em;
}

.tourNoShowsHeader {
    text-align: center;
    font-size: 4vmin;
    padding-top: .7em;
}

.tourDates {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1em;
}

.tourDateContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: flex-start; */
    flex-wrap: wrap;
    width: 85%;
}

.noShowsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-wrap: wrap;
    min-width: 90%;
}

.noShowsHeader {
    font-size: 1.3em;
    color: white;
    width: 90%;
}

.tourDateInfoBox {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 50%;
    flex-direction: column;
}

.tourDate {
    margin-left: .8em;
    margin-bottom: .5em;
}

.tourButtonContainer {
    display: flex;
    flex-direction: flex-end;
}

.tourDateButton {
    margin-left: .8em;
    margin-bottom: .5em;
    padding: 1em 3.5em 1em 3.5em;
    background-color: white;
    font-size: .8em;
    width: 80%;
}

.tourDateButton:hover {
    background-color: black;
    color: white;
}

.day {
    font-size: 1.5em;
}
/* 
.month {
    font-size: 1.2em;
} */

  a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
    display: block;
}



@media only screen and (min-width: 50px) {
    .bio { 
       font-size: .8em; 
    }
 }

 @media only screen and (min-width: 1000px) {

    .bio { 
       font-size: 1.2em; 
    }
 }