.contactContainer {
    background-color:black;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.contactSection {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 33%
}

.contactSectionEpk {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* min-width: 33% */
}

.shareHeader {
    margin: 0;
    padding: 1em 1em 0em 1em;
}

.headerSection {
    display:flex;
    justify-content: center;
    align-items: center;
}

.lowfyeHashTag {
    text-align: center;
    font-size: 1em;
    margin-left: .6em;
}

.icons {
    display:flex;
    justify-content: center;
    box-shadow: #1DB954;
}

.fab {  
    padding: 2.3vmin;  
    text-align: center;  
    margin: 1em .5em;  
    font-size: 2em;  
    width: 1em;
    height: 1em;
    border-radius: 50%;
    /* box-shadow: 0 0 7px #ccc; */
    border: solid 1px rgb(87, 87, 87);
}  

.fa {  
    padding: 2.3vmin;  
    text-align: center;  
    margin: 1em .5em;  
    font-size: 2em;  
    /* width: 1em; */
    height: 1em;
    border-radius: 50%;
    /* box-shadow: 0 0 7px #ccc; */
    border: solid 1px rgb(87, 87, 87);
}

.phone {
    margin-top: 1em;
}

.fa:hover {  
    opacity: 0.6;  
} 

.fab:hover {  
    opacity: 0.6;  
} 

.fa-facebook {  
    background: #3B5998;  
    color: white;
}  

.fa-twitter {  
    background: #55ACEE;  
    color: white;  
}  

.fa-envelope {  
    background: #3167ef;  
    color: white;  
}  

.fa-instagram {  
    background: #bc2a8d;  
    color: white;
    filter: drop-shadow(2px 0px 0px #fccc63) 
            drop-shadow(-2px -2px 0px #fbad50)
            drop-shadow(2px 0px 2px #5851DB);
}  
.fa-youtube {  
    background: #bb0000;  
    color: white;  
}  

.fa-spotify {  
    background: #000000;  
    color: #1DB954;  
}  

.fa-tiktok {
    color: #111111;
    background: #e4e4e4; 
    filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
  }

/* Styles for Mobile Mode */
@media screen and (max-width: 768px) {
    .shareHeader {
        margin: 0;
        /* padding: 1em 1em 0em 1em; */
    }

    .contactSection {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        min-width: 33%
    }

    .fab {  
        padding: 2.3vmin;  
        text-align: center;  
        margin: 0em .5em;  
        font-size: 2em;  
        width: 1em;
        height: 1em;
        border-radius: 50%;
    }  

    .fa {  
        padding: 2.3vmin;  
        text-align: center;  
        margin: 0em .5em;  
        font-size: 2em;  
        /* width: 1em; */
        height: 1em;
        border-radius: 50%;
    }

    .bottomEnvelope {
        margin-bottom: 1rem;
    }
}