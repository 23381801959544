.mp3Box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    min-width: 75%;
    align-items: center;
    align-content: center;
}

.songs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 1.5em;
    align-items: center;
}

.techRiderContainer {
    display: flex;
    justify-content: center;
    /* min-width: 95em; */
}

.audioTrack {
    /* margin-right: 17vmax; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addMargin {
    margin:1em;
}


.photoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.epkPhoto {
    object-fit: cover;
    width: 22em;
    height: 22em;
    margin: 1.5em;
}

.preSavePhoto {
    object-fit: cover;
    width: 3em;
    height: 3em;
    margin-left: 1em
}

.preSavePhoto:hover {  
    opacity: 0.5;  
} 

.singleOut {
    display: flex;
    align-items: center;
}

.spotifySingleEpk {
    object-fit: contain;
}

.spotifySingle {
    /* background-color: black; */
    /* color: black; */
    border-radius: 0% !important;
    display: flex;
    justify-content: center;
    max-width: 12em;
    min-width: 12rem;
    object-fit: contain;
    /* opacity: .7; */
}

.spotifySingleEpk:hover {
    /* background-color: rgb(67, 67, 67) !important; */
    /* color:white !important; */
    opacity: .95 !important;
}

.promoVideo {
    width: 18rem;
    height: 32rem;
    /* display: flex; */
    border: black;
    border-radius: 1rem;
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
}

.promoVideoContainer {
    display: flex;
    justify-content: center;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .promoVideo {
        width: 18rem;
        height: 32rem;
        /* display: flex; */
        border: black;
        border-radius: 1rem;
        -moz-box-shadow: 0 0 3px #ccc;
        -webkit-box-shadow: 0 0 3px #ccc;
        box-shadow: 0 0 3px #ccc;
    }
}

/* Smartphones (landscape) ----------- */
@media only screen 
and (min-width : 321px) {
/* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen 
and (max-width : 320px) {
    .promoVideo {
        width: 18rem;
        height: 32rem;
        /* display: flex; */
        border: black;
        border-radius: 1rem;
        -moz-box-shadow: 0 0 3px #ccc;
        -webkit-box-shadow: 0 0 3px #ccc;
        box-shadow: 0 0 3px #ccc;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
    .promoVideo {
        width: 18rem;
        height: 32rem;
        /* display: flex; */
        border: black;
        border-radius: 1rem;
        -moz-box-shadow: 0 0 3px #ccc;
        -webkit-box-shadow: 0 0 3px #ccc;
        box-shadow: 0 0 3px #ccc;
    }
}

/* iPads (landscape) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
/* Styles */
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 1224px) {
    .promoVideo {
        width: 22.5rem;
        height: 40rem;
        /* display: flex; */
        border: black;
        border-radius: 1rem;
        -moz-box-shadow: 0 0 3px #ccc;
        -webkit-box-shadow: 0 0 3px #ccc;
        box-shadow: 0 0 3px #ccc;
    }
}

/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
/* Styles */
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
/* Styles */
}