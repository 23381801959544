.musicContainer {
    display: flex;
    justify-content: space-evenly;
}

.bandPicMusicPage {
    margin: 1rem;
    width: 40%;
    object-fit: inherit;
    /* width: 100%;
    height: 100%; */ 
}

.preSaveLink {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    /* margin-left: 8.2rem;
    width:50% */
    align-self: center;
}

.iconContainer {
    color: white;
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.firstMusicItem {
    margin-top: 5.5vmax;
}

.fa-youtube-play {  
    background: #bb0000;  
    color: white;  
}  

.musicIcon {
    background-color: white;
    border-radius: 0% !important;
    display: flex;
    justify-content: center;
    min-width: 15em;
    padding: .8em;
    object-fit: contain;
    opacity: .7;
}

.musicIcon:hover {
    background-color: rgb(67, 67, 67) !important;
    opacity: .6 !important;
}

.preSave {
    color: black;
}

.spotifySingle {
    border-radius: 0% !important;
    margin-top: 1rem;
    margin-bottom:1rem;
    display: flex;
    justify-content: center;
    min-width: 9em;
}

.spotifySingle:hover {
    opacity: .95 !important;
}

/* Styles for Mobile Mode */
@media screen and (max-width: 768px) {
      
    .iconContainer {
        color: white;
        background-repeat: no-repeat;
        object-fit: cover;
        background-position: center;
        background-size: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .musicIcon {
        max-height: 1em;
        background-color: white;
        border-radius: 0% !important;
        display: flex;
        justify-content: center;
        min-width: 6em;
        object-fit: contain;
        opacity: .7;
        padding: .2em;
        margin:.2em;
    }

    .bandPicMusicPage {
        margin: .3rem;
        object-fit: inherit;
        /* width: 40%; */
        /* height: 80%; */
    }

    .preSaveLink {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 70%;
        max-height: 60%;
        /* margin-top: 1rem; */
        /* margin-left: 8.2rem;
        width:50% */
        margin: .5rem;
        align-self: center;
        overflow: hidden;
        object-fit: contain;
    }
}