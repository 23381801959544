.navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-right: 1.5em;
    min-width: 55%;
}

.navbarEpk {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 1.5em;
    min-width: 55%;
}

.navbarContainer {
    display: flex;
    align-items: center;
    border-bottom: solid white .7em;
}

.navbarLogo {
    width: 15%
}

.navbar__item {
    list-style-type: none;
    padding: .5em;
    text-align: center;
    margin: 0em 1em .5em 0em;
    border: solid .2em white;
    width: 27%;
    max-height: 20%;
    color: white;
    text-decoration: none;
}

.navbar__item_epk {
    list-style-type: none;
    padding: .5em 4.5em;
    text-align: center;
    margin: 0em 1em .5em 0em;
    border: solid .2em white;
    width: 27%;
    max-height: 20%;
    color: white;
    text-decoration: none;
}

.navbar__item:hover {
    background-color: rgb(49, 49, 49);
}

.navbar__item_epk:hover {
    background-color: rgb(49, 49, 49);
}

.navbar__logo_item {
    text-align: center;
    min-width: 45%;
}

/* .navbar__link {
    color: white;
    text-decoration: none;
} */

ul {
    list-style: none;
    padding-left: 0;
}


.logoNav {
    width: 8vmax;
}