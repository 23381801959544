.logoContainer {
    display: flex;
    justify-content: center;
}

.resize {
    width: 77%;
    height: auto;
}

.bioContainer {
    display: flex;
    justify-content: center;
}

.bio {
    max-width: 90%;
    width: 65vmax;
    /* font-size: 1.6vmax; */
    line-height: 1.4em;
    font-size: .9em;
}

@media only screen and (min-width: 50px) {
    .bio { 
       font-size: .8em; 
    }

    .logo {
        width: 100%;
        /* min-width: 90vmin; */
    }
    
    .bandPic {
        width: 70%;
        /* min-width: 80vmin; */
        height: auto;
    }
 }

 @media only screen and (min-width: 1000px) {
    .logo {
        width: 60%;
        /* min-width: 90vmin; */
    }
    
    .bandPic {
        width: 50%;
        /* min-width: 80vmin; */
        height: auto;
    }

    .bio { 
       font-size: 1.2em; 
    }
 }